.action-button {
  color: #ccc;
  cursor: pointer;
  background: none;
  border: 2px solid #ccc;
  border-radius: 4px;
  padding: 10px 28px;
}

.action-button:hover {
  color: #000;
  background: #ccc;
}

.full-width-banner {
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
  margin: 300px 10% 20%;
  display: flex;
  top: 0;
  left: 0;
}

.h1 {
  color: #ccc;
  white-space: nowrap;
  width: 100%;
  z-index: -1;
  flex-direction: column;
  margin: 100px auto;
  font-weight: 400;
  line-height: 1.5;
  display: flex;
  position: relative;
  overflow: hidden;
}

.h1 span {
  font-size: 4rem;
}

.message {
  z-index: -1;
  padding-left: .5rem;
  font-family: Caveat, cursive;
  font-weight: 700;
  animation: 7s ease-in-out forwards openclose;
  display: block;
  position: absolute;
  left: 200px;
}

@keyframes openclose {
  0% {
    top: 0;
  }

  35% {
    top: 0;
  }

  38% {
    top: -6rem;
  }

  66% {
    top: -6rem;
  }

  71% {
    top: -12rem;
  }

  100% {
    top: -12rem;
  }
}

.svg-image {
  min-width: 15%;
  aspect-ratio: 1 / 1;
  background-color: #ebebeb33;
  border-radius: 30px;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding: 20px;
  display: flex;
}

.svg-slider {
  width: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: -300px;
  display: flex;
}

@media only screen and (max-width: 800px) {
  .full-width-banner {
    margin: 50px 5% 10%;
  }

  .message {
    left: 170px;
  }

  .h1 span {
    font-size: 3.3rem;
  }

  @keyframes openclose {
    0% {
      top: 0;
    }

    35% {
      top: 0;
    }

    38% {
      top: -4.8rem;
    }

    66% {
      top: -4.8rem;
    }

    71% {
      top: -9.9rem;
    }

    100% {
      top: -9.9rem;
    }
  }

  .svg-slider {
    justify-content: center;
    align-items: center;
    margin-top: 0;
    display: flex;
  }
}

@media only screen and (max-width: 600px) {
  .full-width-banner {
    margin: 50px 5% 10%;
  }

  .message {
    left: 90px;
  }

  .h1 span {
    font-size: 1.8rem;
  }

  @keyframes openclose {
    0% {
      top: 0;
    }

    35% {
      top: 0;
    }

    38% {
      top: -2.8rem;
    }

    66% {
      top: -2.8rem;
    }

    71% {
      top: -5.4rem;
    }

    100% {
      top: -5.4rem;
    }
  }

  .svg-slider {
    justify-content: center;
    align-items: center;
    margin-top: 0;
    display: flex;
  }
}

.nav-link {
  color: #aaa;
  text-decoration: none;
}

.nav-link:hover {
  color: #eee;
}

.footer {
  width: 100%;
  justify-content: center;
  align-items: center;
  font-size: .9rem;
  display: flex;
}

.mid-part {
  margin: 0 auto;
}

/*# sourceMappingURL=index.27eb5059.css.map */
