.action-button{
  background: none;
  border: 2px solid #ccc;
  border-radius: 4px;
  padding: 10px 28px;
  color: #ccc;
  cursor: pointer;

  &:hover{
    background:#ccc;
    color: black;
  }
}

.full-width-banner{
    // position: absolute;
    top: 0;
    left: 0;
    // height: 00px;
    // width: 100%;
    display: flex;
    margin: 300px 10% 20%;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    // background-color: #ccc;
    // background: rgba(235, 235, 235, 0.3);
    // background-image: 
    //     radial-gradient(at 47% 33%, rgb(110, 91, 171) 0, transparent 59%), 
    //     radial-gradient(at 82% 65%, rgb(43, 95, 86) 0, transparent 55%);
    // z-index: -1;
    // border: 2px solid white;

    
    
    // background-color: pink;
}
  
.h1{
    // text-align: center;
    // background: pink;
    // margin-top: 100px;
    margin: 100px auto;
    color: #ccc;
    // font-family: tahoma;
    // font-size: 3rem;
    font-weight: 400;
    line-height: 1.5;
    // text-transform: uppercase;
    white-space: nowrap;
    overflow: hidden;
    position: relative;
    width: 100%;
    z-index: -1;
    display: flex;
    flex-direction: column;
}
.h1 span {
    font-size: 4rem;
    // margin-left: 40px;
}
  
.message {
    // margin-top: 100px;
    // background-color: yellow;
    font-family: 'Caveat', cursive;
    // color: rgb(249, 249, 176);
    display: block;
    font-weight: 700;
    // overflow: hidden;
    position: absolute;
    z-index: -1;
    padding-left: 0.5rem;
    // top: 0.2rem;
    left: 200px;
    animation: openclose 7s ease-in-out 1 forwards;
    // font-size: 2.5rem;
    // text-decoration: underline;
}

@keyframes openclose {
  // For Infinite Loop
  // 0% {
  //   top: 0rem;
  // //   width: 0;
  // }
  // 25% {
  //   top: 0rem;
  // //   width: 0;
  // }
  // 32% {
  //   top: -6rem; 
  // }
  // 66% {
  //   top: -6rem;
  // //   width: 0;
  // //   text-indent: 0;
  // }
  // 71% {
  //   top: -12rem;
  // //   width: 0;
  // //   text-indent: 5px;
  // }
  
  // 98% {
  //   top: -12rem;
  // //   width: 0;
  // //   text-indent: 0;
  // }
  // 100%{
  //   top: 0rem;
  // }

  // For One Time : No Loop
    0% {
      top: 0rem;
    //   width: 0;
    }
    35% {
      top: 0rem;
    //   width: 0;
    }
    38% {
      top: -6rem; 
    }
    66% {
      top: -6rem;
    //   width: 0;
    //   text-indent: 0;
    }
    71% {
      top: -12rem;
    //   width: 0;
    //   text-indent: 5px;
    }
    
    100% {
      top: -12rem;
    //   width: 0;
    //   text-indent: 0;
    }
}
  
.svg-image{
  min-width: 15%;
  // max-height: 100%;
  aspect-ratio: 1/1;
  margin: 0 auto;
  background-color: rgba(235, 235, 235, 0.2);
  padding: 20px;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.svg-slider{
  // background: pink;
  // position: absolute;
  // top: 150px;
  // right: 30px;
  // margin: 0 auto;
  // overflow: hidden;
  // z-index: -1;
  width: 100%;
  margin-top: -300px;
  display: flex;
  flex-direction: row;
  // display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width:800px){
  .full-width-banner{
    margin: 50px 5% 10%;
  }
  .message{
    // background-color: pink;
    // margin-top: 100px;
    // top: 0;
    left:170px;
  }
  .h1 span{
    font-size: 3.3rem;
  }
  @keyframes openclose {
    0% {
      top: 0rem;
    //   width: 0;
    }
    35% {
      top: 0rem;
    //   width: 0;
    }
    38% {
      top: -4.8rem; 
    }
    66% {
      top: -4.8rem;
    //   width: 0;
    //   text-indent: 0;
    }
    71% {
      top: -9.9rem;
    //   width: 0;
    //   text-indent: 5px;
    }
    
    100% {
      top: -9.9rem;
    //   width: 0;
    //   text-indent: 0;
    }
  }
  .svg-slider{
    // background-color: pink;
    margin-top: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media only screen and (max-width:600px){
  .full-width-banner{
    margin: 50px 5% 10%;
  }
  .message{
    // background-color: pink;
    // margin-top: 100px;
    // top: 0;
    left: 90px;
  }
  .h1 span{
    font-size: 1.8rem;
  }
  @keyframes openclose {
    0% {
      top: 0rem;
    //   width: 0;
    }
    35% {
      top: 0rem;
    //   width: 0;
    }
    38% {
      top: -2.8rem; 
    }
    66% {
      top: -2.8rem;
    //   width: 0;
    //   text-indent: 0;
    }
    71% {
      top: -5.4rem;
    //   width: 0;
    //   text-indent: 5px;
    }
    
    100% {
      top: -5.4rem;
    //   width: 0;
    //   text-indent: 0;
    }
  }
  // .svg-image{
    // max-height: 100px;
    // align-items: flex-end;
  // }
  .svg-slider{
    // background-color: pink;
    margin-top: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}